export const initialState = {
	user                : null,
	adminUserForLoginAs : null,
	salesUserForLoginAs : null,
	host                : window.location.origin,
	hostname            : window.location.hostname.replace('app.', '').replace('admin.', '').replace('sales.', ''),
	mobileReportView    : 'base',
	isSalesView         : window.location.origin.indexOf('sales.') > -1 || window.location.href.indexOf('/sales') > -1
};

export const actionTypes = {
	SET_USER                    : 'SET_USER',
	SET_ADMIN_USER_FOR_LOGIN_AS : 'SET_ADMIN_USER_FOR_LOGIN_AS',
	SET_SALES_USER_FOR_LOGIN_AS : 'SET_SALES_USER_FOR_LOGIN_AS',
	SET_MOBILE_REPORT_VIEW      : 'SET_MOBILE_REPORT_VIEW'
};

const reducer = (state, action) => {
	//console.log(action);
	switch (action.type) {
		case actionTypes.SET_USER:
			return {
				...state,
				user : action.user
			};
		case actionTypes.SET_ADMIN_USER_FOR_LOGIN_AS:
			return {
				...state,
				adminUserForLoginAs : action.adminUserForLoginAs
			};
		case actionTypes.SET_SALES_USER_FOR_LOGIN_AS:
			return {
				...state,
				salesUserForLoginAs : action.salesUserForLoginAs
			};
		case actionTypes.SET_MOBILE_REPORT_VIEW:
			return {
				...state,
				mobileReportView : action.mobileReportView
			};
		default:
			return state;
	}
};

export default reducer;
