import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import './RequirementsApproved.css';
import { Fragment } from 'react';
import { GetRequirementDetails, GetRequirementsMessages } from '../../../Services';
import Spinner from '../../../components/spinner/Spinner';
import moment from 'moment';

const RequirementsApproved = () => {
	const [ requirementsData, setRequirementsData ] = useState({});
	const [ requirementsMessages, setRequirementsMessages ] = useState();
	const { requirementsId } = useParams();

	useEffect(() => {
		document.body.classList.add('gray-background');

		getOrderRequirementsDetails();
	}, []);

	const getOrderRequirementsDetails = async () => {
		if (requirementsId) {
			const response = await GetRequirementDetails(requirementsId);

			if (response && response.id) {
				let tempRequirementsData = response;

				setRequirementsData(tempRequirementsData);

				getRequirementsMessages(requirementsId);
			}
		}
	};

	const getRequirementsMessages = async (id) => {
		const response = await GetRequirementsMessages(id);

		if (response.success && response.data) {
			//sort messages from latest to oldest
			let tempMessages = response.data.sort(function(a, b) {
				return new Date(b.dateCreated) - new Date(a.dateCreated);
			});

			setRequirementsMessages(tempMessages);
		}
	};

	return (
		<Fragment>
			<div className="container-fluid requirements-approved-container">
				<div className="container">
					<div className="requirements-approved-container-inner">
						<div className="row">
							<div className="col-12 title-wrapper">
								<h2 className="title text-center">Map Boost Setup Approved</h2>
							</div>

							{requirementsData.dateApproved && (
								<div className="col-12 mt-2 text-center">
									<p>
										<span>Approved On:</span>
										<span className="ms-2">
											{moment(requirementsData.dateApproved).format('MM/DD/yyyy')}
										</span>
									</p>
								</div>
							)}

							<div className="col-12 mb-4">
								<hr />
							</div>

							{!requirementsData.status && (
								<div className="col-12 text-center">
									<Spinner />
								</div>
							)}

							{requirementsData &&
							requirementsData.status && (
								<Fragment>
									{requirementsData.status !== 'Completed' ? (
										<div className="col-12 text-center">
											<p>This request is not approved.</p>
										</div>
									) : (
										<Fragment>
											<div className="col-12 mb-2">
												<p>
													Please verify the information below and the{' '}
													<strong>
														click the "Approve" button at the bottom of this page
													</strong>{' '}
													and we will get started on building this campaign.
												</p>
											</div>

											<div className="col-12">
												<div className="campaign-info-wrapper">
													<div className="col-12 mb-1">
														<p className="mb-0">
															<strong>Business Name:</strong>
														</p>
														<p>{requirementsData.company}</p>
													</div>

													<div className="col-12 mb-1">
														<p className="mb-0">
															<strong>Business Address:</strong>
														</p>
														<p>{requirementsData.address}</p>
													</div>

													<div className="col-12 mb-1">
														<p className="mb-0">
															<strong>Business Profile:</strong>
														</p>
														<p>
															<a
																href={requirementsData.googleBusinessUrl}
																target="_blank"
															>
																{requirementsData.googleBusinessUrl}
															</a>
														</p>
													</div>

													<div className="col-12 mb-3">
														<p className="mb-1">
															<strong>Campaign Services:</strong>
														</p>
														{requirementsData.services && (
															<Fragment>
																<pre className="mb-2 ms-4">
																	{requirementsData.services}
																</pre>
															</Fragment>
														)}
													</div>

													<div className="col-12 mb-2">
														<p className="mb-1">
															<strong>Campaign Keywords:</strong>
														</p>
														<p>
															Below are the keywords that we have verified that get the
															most search volume and display business profiles on the
															Google search results.
														</p>
														{requirementsData.keywords && (
															<Fragment>
																<pre className="mb-2 ms-4">
																	{requirementsData.keywords}
																</pre>
															</Fragment>
														)}
													</div>
												</div>
											</div>

											{requirementsMessages && (
												<div className="col-12">
													<h4 className="my-2">Requests & Messages</h4>
													<div className="messages-wrapper">
														<Fragment>
															{requirementsMessages.map((message, index) => {
																let messageDate = moment(message.dateCreated).format(
																	'DD/MM/YYYY HH:mm'
																);

																return (
																	<Fragment key={index}>
																		<div
																			className={`message-wrapper ${message.direction.toLowerCase()}`}
																		>
																			<div className={`message-details`}>
																				<pre className="mb-0">
																					{message.message}
																				</pre>
																				<div className="message-date">
																					{messageDate}
																				</div>
																			</div>
																		</div>
																	</Fragment>
																);
															})}
														</Fragment>
													</div>
												</div>
											)}

											<div className="col-12 mb-4">
												<hr />
											</div>

											<div className="col-12 title-wrapper">
												<h2 className="title text-center">Approve & Get Launched!</h2>
											</div>

											<div className="col-12">
												<p className="mb-1">
													If the{' '}
													<strong>
														business profile information and keywords above are ok
													</strong>, then
													<u className="ms-2">click the Approve button below</u> and your
													campaign will be launched within 3-5 business days.
												</p>
												<p className="my-3">
													<strong>Here's What Happens Next:</strong>
												</p>
												<p className="mb-3">
													You will receive an email & SMS notification with a link to your
													campaign launch report that will show where your Google Business
													Profile currently ranks in your service area for each of the
													keywords above.
												</p>
												<p className="mb-3">
													We will also provide a link to your "local directory site" that will
													have pages optimized for the services you offer and locations within
													your service area. (see the explainer video)
												</p>
												<p className="mb-3">
													Once the campaign is launched Google will begin to index and rank
													all of the directory site pages we created and you will receive the
													2nd report in 30 days showing the initial results.
												</p>
												<p className="mb-3">
													Our expectation is <strong>after 30 days</strong> you will see many
													directory site pages for your business listed #1 at the top of
													Google orgnaic search results.
												</p>
												<p className="mb-3">
													<u>Every 30 days</u> we will send a campaign update report tracking
													the progress and rank improvements of your Google Business Profile.
												</p>
											</div>
										</Fragment>
									)}
								</Fragment>
							)}
						</div>
					</div>
				</div>
			</div>
		</Fragment>
	);
};

export default RequirementsApproved;
