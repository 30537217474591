import './ProfileIcon.css';
import { useEffect, useState, Fragment } from 'react';
import AvatarLettersIcon from '../avatar/AvatarLettersIcon';
import { Link } from 'react-router-dom';
import { deleteToken, RemoveSalesUserForLoginAs } from '../../../utils/StorageUtil';
import { actionTypes } from '../../../reducer';
import { useStateValue } from '../../../StateProvider';
import { useNavigate } from 'react-router';
import { useLocation } from 'react-router-dom';
import { isMobile } from '../../../utils/Common';
// import LogoutIcon from '../../img/sign_out_icon.png';

const ProfileIcon = ({ onLinkClick }) => {
	const [ { user }, dispatch ] = useStateValue();
	const [ tempUser, setTempUser ] = useState({});
	const [ showLogoutLink, setShowLogoutLink ] = useState(false);
	const navigate = useNavigate();
	const location = useLocation();

	useEffect(
		() => {
			setTempUser(user);

			if (isMobile() || location.pathname.includes('/account')) {
				setShowLogoutLink(true);
			} else {
				setShowLogoutLink(false);
			}
		},
		[ user, location ]
	);

	// useEffect(
	// 	() => {
	// 		const params = new URLSearchParams(location.search);

	// 		if (params && params.get('logout')) {
	// 			deleteToken();

	// 			dispatch({
	// 				type : actionTypes.SET_USER,
	// 				user : null
	// 			});
	// 		}
	// 	},
	// 	[ user, location ]
	// );

	const logout = () => {
		//navigate('/login?logout=true');
		navigate('/login');

		setTimeout(function() {
			deleteToken();

			dispatch({
				type : actionTypes.SET_USER,
				user : null
			});

			RemoveSalesUserForLoginAs();

			dispatch({
				type                : actionTypes.SET_SALES_USER_FOR_LOGIN_AS,
				salesUserForLoginAs : null
			});
		}, 500);
	};

	const handleLinkClick = () => {
		if (onLinkClick) {
			onLinkClick();
		}
	};

	return (
		<div className="profile-details-container">
			<Fragment>
				<div className="profile-details-inner">
					<AvatarLettersIcon name={tempUser.displayName} />

					<div className="profile-details">
						<Link to="/account" className="profile-details-link" onClick={handleLinkClick}>
							<div className="full-name">{tempUser.displayName}</div>
							<div className="manage-account">Manage Account</div>
						</Link>
						{showLogoutLink && (
							// <Link className="logout-link mt-2" to="/login?logout=true">
							// 	<i className="fa-solid fa-right-from-bracket me-2" />
							// 	<span>Log Out</span>
							// </Link>
							<div className="logout-link mt-2" onClick={logout}>
								<i className="fa-solid fa-right-from-bracket me-2" />
								<span>Log Out</span>
							</div>
						)}
					</div>
				</div>
			</Fragment>
		</div>
	);
};

export default ProfileIcon;
