import axios from 'axios';
import { getToken } from './utils/StorageUtil';

let apiHost = 'https://gbpapi.gbpboost.com';
// let apiHost = 'https://dev-api.gbpboost.com';
const apiKey = 'AIzaSyBukTa2y70Uz3uRpXqhlmZdkBB3WAhxmvg';
const apiHeader = 'X-API-KEY';
const apiHeaderValue = 'IQO?EXZiro?-|m%X15;vx1Q>L}bxb,<?M7=(J56Ss[K7o+;^6a7}J!dW6i8LJk2';

if (window.location.origin) {
	if (window.location.origin.indexOf('localhost') > -1) {
		//apiHost = 'https://localhost:7172';
		apiHost = 'https://dev-report-api.mapboost.com';
	}

	if (window.location.origin.indexOf('dev.') > -1 || window.location.origin.indexOf('dev-') > -1) {
		apiHost = 'https://dev-report-api.mapboost.com';
	}
}

// setup axios to inject the authorization header automatically
// if token is present in store
axios.interceptors.request.use((config) => {
	let token = getToken();

	if (token && token.trim() !== '') {
		config.headers.Authorization = `Bearer ${token}`;
	}

	return config;
});

export const GetBusinessInfoTaskPost = async (data) => {
	const response = await axios
		.post(apiHost + '/dataforseo/business/info/tasks/post', JSON.stringify(data), {
			headers : {
				[apiHeader]    : apiHeaderValue,
				'Content-Type' : 'application/json'
			}
		})
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { IsSuccessful: false, Message: err } };
		});

	return response.data;
};

export const GetBusinessInfoLive = async (data) => {
	const response = await axios
		.post(apiHost + '/dataforseo/business/info/live', JSON.stringify(data), {
			headers : {
				[apiHeader]    : apiHeaderValue,
				'Content-Type' : 'application/json'
			}
		})
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { IsSuccessful: false, Message: err } };
		});

	return response.data;
};

export const GetLocationsByCountry = async (countryCode) => {
	const response = await axios
		.get(apiHost + '/dataforseo/locations?countryCode=' + countryCode, {
			headers : {
				[apiHeader]    : apiHeaderValue,
				'Content-Type' : 'application/json'
			}
		})
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { IsSuccessful: false, Message: err } };
		});

	return response.data;
};

export const GetBusinessProfilePosts = async (data) => {
	const response = await axios
		.post(apiHost + '/dataforseo/business/profile/posts', JSON.stringify(data), {
			headers : {
				[apiHeader]    : apiHeaderValue,
				'Content-Type' : 'application/json'
			}
		})
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { IsSuccessful: false, Message: err } };
		});

	return response.data;
};

export const GetBusinessProfileQA = async (data) => {
	const response = await axios
		.post(apiHost + '/dataforseo/business/profile/qa', JSON.stringify(data), {
			headers : {
				[apiHeader]    : apiHeaderValue,
				'Content-Type' : 'application/json'
			}
		})
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { IsSuccessful: false, Message: err } };
		});

	return response.data;
};

export const GetOnPageSEO = async (data) => {
	const response = await axios
		.post(apiHost + '/dataforseo/onpage/seo', JSON.stringify(data), {
			headers : {
				[apiHeader]    : apiHeaderValue,
				'Content-Type' : 'application/json'
			}
		})
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { IsSuccessful: false, Message: err } };
		});

	return response.data;
};

export const GetBacklinksSummary = async (data) => {
	const response = await axios
		.post(apiHost + '/dataforseo/backlinks/summary', JSON.stringify(data), {
			headers : {
				[apiHeader]    : apiHeaderValue,
				'Content-Type' : 'application/json'
			}
		})
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { IsSuccessful: false, Message: err } };
		});

	return response.data;
};

export const GetDSEOTrendsExplore = async (data) => {
	const response = await axios
		.post(apiHost + '/dataforseo/trends/dseo/explore', JSON.stringify(data), {
			headers : {
				[apiHeader]    : apiHeaderValue,
				'Content-Type' : 'application/json'
			}
		})
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { IsSuccessful: false, Message: err } };
		});

	return response.data;
};

export const GetKeywordsForSite = async (data) => {
	const response = await axios
		.post(apiHost + '/dataforseo/keywords/for-site', JSON.stringify(data), {
			headers : {
				[apiHeader]    : apiHeaderValue,
				'Content-Type' : 'application/json'
			}
		})
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { IsSuccessful: false, Message: err } };
		});

	return response.data;
};

export const GetKeywordsSearchVolume = async (data) => {
	const response = await axios
		.post(apiHost + '/dataforseo/keywords/search-volume', JSON.stringify(data), {
			headers : {
				[apiHeader]    : apiHeaderValue,
				'Content-Type' : 'application/json'
			}
		})
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { IsSuccessful: false, Message: err } };
		});

	return response.data;
};

export const GetApiStatus = async () => {
	const response = await axios
		.get(apiHost + '/dataforseo/api/status', {
			headers : {
				[apiHeader]    : apiHeaderValue,
				'Content-Type' : 'application/json'
			}
		})
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { IsSuccessful: false, Message: err } };
		});

	return response.data;
};

export const GetAccountBalance = async () => {
	const response = await axios
		.get(apiHost + '/dataforseo/account/balance', {
			headers : {
				[apiHeader]    : apiHeaderValue,
				'Content-Type' : 'application/json'
			}
		})
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { IsSuccessful: false, Message: err } };
		});

	return response.data;
};

// SerpApi -------------------
export const GetBusinessProfileDetails = async (data) => {
	const response = await axios
		.post(apiHost + '/serp/business/details', JSON.stringify(data), {
			headers : {
				[apiHeader]    : apiHeaderValue,
				'Content-Type' : 'application/json'
			}
		})
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { IsSuccessful: false, Message: err } };
		});

	return response.data;
};

export const GetBusinessProfileReviews = async (data) => {
	const response = await axios
		.post(apiHost + '/serp/business/reviews', JSON.stringify(data), {
			headers : {
				[apiHeader]    : apiHeaderValue,
				'Content-Type' : 'application/json'
			}
		})
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { IsSuccessful: false, Message: err } };
		});

	return response.data;
};

export const GetBusinessProfilePhotos = async (data) => {
	const response = await axios
		.post(apiHost + '/serp/business/photos', JSON.stringify(data), {
			headers : {
				[apiHeader]    : apiHeaderValue,
				'Content-Type' : 'application/json'
			}
		})
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { IsSuccessful: false, Message: err } };
		});

	return response.data;
};

export const GetGoogleSearchResults = async (data) => {
	const response = await axios
		.post(apiHost + '/serp/google/search/results', JSON.stringify(data), {
			headers : {
				[apiHeader]    : apiHeaderValue,
				'Content-Type' : 'application/json'
			}
		})
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { IsSuccessful: false, Message: err } };
		});

	return response.data;
};

export const GetAccountData = async () => {
	const response = await axios
		.get(apiHost + '/serp/account/data', {
			headers : {
				[apiHeader]    : apiHeaderValue,
				'Content-Type' : 'application/json'
			}
		})
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { IsSuccessful: false, Message: err } };
		});

	return response.data;
};

// Rapid Api -------------------
export const GetDomainAgeAndAuthority = async (data) => {
	const response = await axios
		.post(apiHost + '/rapidapi/domain/authority', JSON.stringify(data), {
			headers : {
				[apiHeader]    : apiHeaderValue,
				'Content-Type' : 'application/json'
			}
		})
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { IsSuccessful: false, Message: err } };
		});

	return response.data;
};

// Google Api -------------------
export const GetPageSpeed = async (data) => {
	const response = await axios
		.post(apiHost + '/googleapi/page/speed', JSON.stringify(data), {
			headers : {
				[apiHeader]    : apiHeaderValue,
				'Content-Type' : 'application/json'
			}
		})
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { IsSuccessful: false, Message: err } };
		});

	return response.data;
};
