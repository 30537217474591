export const getToken = () => {
	return localStorage.getItem('gbp-up-token');
};

export const setToken = (token) => {
	localStorage.setItem('gbp-up-token', token);
};

export const deleteToken = () => {
	localStorage.removeItem('gbp-up-token');
};

//parent sales rep for logged in as user -----------------------
export const StoreSalesUserForLoginAs = (user) => {
	localStorage.setItem('gbp-up-su-la', JSON.stringify(user));
};

export const GetSalesUserForLoginAs = () => {
	if (localStorage.getItem('gbp-up-su-la')) {
		return JSON.parse(localStorage.getItem('gbp-up-su-la'));
	}

	return null;
};

export const RemoveSalesUserForLoginAs = () => {
	localStorage.removeItem('gbp-up-su-la');
};

//offer type -----------------------------------------------------
export const getOfferType = () => {
	return localStorage.getItem('mb-offer-type');
};

export const setOfferType = (type) => {
	localStorage.setItem('mb-offer-type', type);
};

export const deleteOfferType = () => {
	localStorage.removeItem('mb-offer-type');
};

//business profile ------------------------------------------------
export const StoreBusiness = (business) => {
	localStorage.setItem('mapboost-business', JSON.stringify(business));
};

export const RemoveSalesBusiness = () => {
	localStorage.removeItem('mapboost-sales-business');
};

export const StoreSalesBusiness = (business) => {
	localStorage.setItem('mapboost-sales-business', JSON.stringify(business));
};

export const GetSalesBusiness = () => {
	if (localStorage.getItem('mapboost-sales-business')) {
		return JSON.parse(localStorage.getItem('mapboost-sales-business'));
	}

	return null;
};

export const RemoveSalesTask = () => {
	localStorage.removeItem('mapboost-sales-task');
};

export const StoreSalesTask = (task) => {
	localStorage.setItem('mapboost-sales-task', JSON.stringify(task));
};

export const GetSalesTask = () => {
	if (localStorage.getItem('mapboost-sales-task')) {
		return JSON.parse(localStorage.getItem('mapboost-sales-task'));
	}

	return null;
};
