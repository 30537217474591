import { useState, useEffect, Fragment } from 'react';
import { useStateValue } from '../../StateProvider';
import { actionTypes } from '../../reducer';
import './Login.css';
import { AppLoginAs, GetLoginAsToken } from '../../Services';
import { deleteToken, setToken, StoreSalesUserForLoginAs } from '../../utils/StorageUtil';
import { useLocation, useParams } from 'react-router-dom';
import Spinner from '../spinner/Spinner';

const LoginAs = () => {
	const [ isLoading, setIsLoading ] = useState(false);
	const [ { user }, dispatch ] = useStateValue();
	const { userId } = useParams();
	// const location = useLocation();
	//const [ userIdValue, setUserIdValue ] = useState('');

	// useEffect(
	// 	() => {
	// 		if (userId) {
	// 			setUserIdValue(userId);
	// 		}
	// 	},
	// 	[ userId ]
	// );

	useEffect(
		() => {
			if (userId) {
				loginAs();
			}
		},
		[ userId ]
	);

	const loginAs = async () => {
		setIsLoading(true);

		logoutAndRedirect();

		let data = { id: userId };

		//get new user token
		const loginAsTokenResponse = await GetLoginAsToken(data);

		if (loginAsTokenResponse) {
			setToken(loginAsTokenResponse);

			// //store sales user as parent user
			// StoreSalesUserForLoginAs(user);

			//login new user
			const loginAsResponse = await AppLoginAs(data);

			if (loginAsResponse) {
				const tempLoggedInAsUser = loginAsResponse;

				dispatch({
					type : actionTypes.SET_USER,
					user : tempLoggedInAsUser
				});

				window.location.href = '/campaigns';
			} else {
				logoutAndRedirect('/login');
			}
		} else {
			logoutAndRedirect('/login');
		}

		setIsLoading(false);
	};

	const logoutAndRedirect = (redirectUrl) => {
		deleteToken();

		dispatch({
			type : actionTypes.SET_USER,
			user : null
		});

		if (redirectUrl) {
			window.location.href = redirectUrl;
		}
	};

	return <div className="container-fluid text-center">{isLoading && <Spinner />}</div>;
};

export default LoginAs;
