import './App.css';
import { Route, Routes } from 'react-router-dom';
import { useStateValue } from './StateProvider';
import { Fragment, useState, useEffect } from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { GetUser } from './Services';
import { GetSalesUserForLoginAs, deleteToken, getToken } from './utils/StorageUtil';
import { actionTypes } from './reducer';
import Loader from './components/loader/Loader';
import Login from './components/login/Login';
import MainApp from './components/mainApp/MainApp';
import PublicViewCampaignReportScreenV5 from './screens/reports/publicViewCampaignReportV5/PublicViewCampaignReportScreenV5';
import PublicCustomerReportInfoPage from './screens/reports/customerReportInfo/PublicCustomerReportInfoPage';
import ForgotPassword from './components/forgotPassword/ForgotPassword';
import ResetPassword from './components/resetPassword/ResetPassword';
import OfferOrderPage from './screens/offers/offerOrderPage/OfferOrderPage';
import OfferOrderPageSuccess from './screens/offers/offerOrderPage/OfferOrderPageSuccess';
import CreateRequirements from './screens/requirements/createRequirements/CreateRequirements';
import CreateRequirementsSuccess from './screens/requirements/createRequirements/CreateRequirementsSuccess';
import GridReportTool from './screens/salesUsers/gridReportTool/GridReportTool';
import ReportPublicView from './screens/salesUsers/reportPublicView/ReportPublicView';
import NewReportApiTest from './screens/test/NewReportApiTest';
import RequirementsApprovalRequestPage from './screens/requirements/approval/requirementsApprovalRequestPage/RequirementsApprovalRequestPage';
import RequirementsApprovalChangeThankYouPage from './screens/requirements/approval/requirementsApprovalChangeThankYouPage/RequirementsApprovalChangeThankYouPage';
import RequirementsApprovalThankYouPage from './screens/requirements/approval/requirementsApprovalThankYouPage/RequirementsApprovalThankYouPage';
import RequirementsApproved from './screens/requirements/requirementsApproved/RequirementsApproved';
import LoginAs from './components/login/LoginAs';

function App() {
	const [ { user, host, salesUserForLoginAs }, dispatch ] = useStateValue();
	const [ isLoading, setIsLoading ] = useState(true);
	const [ toastifyPosition, setToastifyPosition ] = useState('bottom-left');
	const [ chatLoaded, setChatLoaded ] = useState(false);

	useEffect(() => {
		window.addEventListener('resize', handleResize);

		handleResize();

		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, []);

	useEffect(() => {
		getUser();
		handleLiveChat();
	}, []);

	const handleResize = () => {
		const windowW = window.innerWidth;

		if (windowW <= 767) {
			setToastifyPosition('bottom-center');
		} else {
			setToastifyPosition('bottom-left');
		}

		if (windowW >= 992) {
			dispatch({
				type             : actionTypes.SET_MOBILE_REPORT_VIEW,
				mobileReportView : ''
			});
		} else {
			dispatch({
				type             : actionTypes.SET_MOBILE_REPORT_VIEW,
				mobileReportView : 'base'
			});
		}
	};

	const logoutAndRedirect = (redirectUrl) => {
		//setTimeout(function() {
		deleteToken();

		dispatch({
			type : actionTypes.SET_USER,
			user : null
		});
		//}, 500);

		window.location.href = redirectUrl;
	};

	const getUser = async () => {
		let token = getToken();

		if (token) {
			try {
				const response = await GetUser();

				if (response && response.id) {
					const { ...tempUser } = response;

					//check if sales user is logged in as regular user
					let tempSalesUserForLoginAs = GetSalesUserForLoginAs();

					if (tempSalesUserForLoginAs) {
						dispatch({
							type                : actionTypes.SET_SALES_USER_FOR_LOGIN_AS,
							salesUserForLoginAs : tempSalesUserForLoginAs
						});
					}

					if (tempUser.isAdmin) {
						// admin user -------------------------------------------

						if (tempUser.isSalesUser || tempSalesUserForLoginAs) {
							// admin and sales user
							dispatch({
								type : actionTypes.SET_USER,
								user : tempUser
							});
						} else {
							//just admin user - redirect to admin url
							logoutAndRedirect('https://app.gbpboost.com/campaigns/list');
						}
					} else if (tempUser.isSalesUser || tempSalesUserForLoginAs) {
						// sales user ----------------------------------------------

						//if user portal url, redirect to sales url
						if (window.location.origin.indexOf('app.') > -1) {
							logoutAndRedirect('https://sales.mapboost.com/campaigns');
						} else {
							dispatch({
								type : actionTypes.SET_USER,
								user : tempUser
							});
						}
					} else {
						// regular user --------------------------------------------

						//if sales url, redirect to user portal url
						if (window.location.origin.indexOf('sales.') > -1) {
							logoutAndRedirect('https://app.mapboost.com/campaigns');
						} else {
							dispatch({
								type : actionTypes.SET_USER,
								user : tempUser
							});
						}
					}
				} else {
					let tempToken = getToken();

					if (tempToken) {
						deleteToken();
					}
				}

				setIsLoading(false);
			} catch (error) {
				let tempToken = getToken();

				if (tempToken) {
					deleteToken();
				}

				setIsLoading(false);
			}
		} else {
			setTimeout(function() {
				setIsLoading(false);
			}, 300);
		}
	};

	const handleLiveChat = () => {
		let pageSlugs = [
			'/customer/report',
			//'/map-boost-approval',
			'/map-boost-approval-change-thank-you',
			'/map-boost-approval-thank-you'
		];
		let currentUrl = window.location.href;

		if (pageSlugs.some((x) => currentUrl.includes(x)) && !chatLoaded) {
			// load LC script
			window.__lc = window.__lc || {};
			window.__lc.license = 10943002;

			if (user) {
				if (user.Status === 'Trial') {
					window.__lc.group = 3;
				} else {
					window.__lc.group = 2;
				}
			} else {
				window.__lc.group = 2;
			}

			var lc = document.createElement('script');
			lc.type = 'text/javascript';
			lc.async = true;
			lc.src =
				('https:' == document.location.protocol ? 'https://' : 'http://') + 'cdn.livechatinc.com/tracking.js';
			var s = document.getElementsByTagName('script')[0];
			s.parentNode.insertBefore(lc, s);

			setChatLoaded(true);
		}
	};

	if (isLoading) {
		return <Loader />;
	}

	return (
		<div className="app">
			<Fragment>
				<Routes>
					{/* <Route path="/*" element={<div />} /> */}

					{user ? (
						<Fragment>
							{user.isSalesUser && (
								<Fragment>
									{/* <Route path="/profile-connect" element={<BusinessProfileConnect />} />
									<Route path="/profile-address" element={<BusinessProfileAddress />} /> */}
									<Route path="/grid-report-tool/:editCompanyId?" element={<GridReportTool />} />
									<Route
										path="/business/:companyId/reports/details/:reportName?"
										element={<GridReportTool />}
									/>
									<Route path="/reports/:reportId" element={<GridReportTool />} />
								</Fragment>
							)}

							<Route
								path="/view/report/v5/:campaignId/*"
								element={<PublicViewCampaignReportScreenV5 />}
							/>
							<Route
								path="/customer/report/:reportHistoryId"
								element={<PublicCustomerReportInfoPage />}
							/>
							<Route path="/report-api/test" element={<NewReportApiTest />} />
							<Route path="/*" element={<MainApp />} />
						</Fragment>
					) : (
						<Fragment>
							<Route path="/login/*" element={<Login />} />
							<Route path="/forgot-password" element={<ForgotPassword />} />
							<Route path="/reset-password" element={<ResetPassword />} />
							{/* <Route exact path="/" element={<div />} /> */}
							<Route path="/*" element={<Login />} />
						</Fragment>
					)}
					<Route path="/login-as/:userId" element={<LoginAs />} />
					<Route path="/orders/:id/requirements" element={<CreateRequirements />} />
					{/* <Route path="/requirements/:id/approve" element={<RequirementsApprovalPage />} /> */}
					<Route path="/requirements/success" element={<CreateRequirementsSuccess />} />
					<Route path="/orders/:id?" element={<OfferOrderPage />} />
					<Route path="/orders/:id/success" element={<OfferOrderPageSuccess />} />
					<Route path="/public/reports/:reportId" element={<ReportPublicView />} />
					<Route
						path="/map-boost-approval/:requirementsId/:cl?"
						element={<RequirementsApprovalRequestPage />}
					/>
					<Route
						path="/map-boost-approval-change-thank-you"
						element={<RequirementsApprovalChangeThankYouPage />}
					/>
					<Route path="/map-boost-approval-thank-you" element={<RequirementsApprovalThankYouPage />} />
					<Route path="/map-boost-approved/:requirementsId" element={<RequirementsApproved />} />
				</Routes>
				<ToastContainer
					position={toastifyPosition}
					autoClose={5000}
					hideProgressBar={false}
					newestOnTop={false}
					closeOnClick
					limit={1}
				/>
			</Fragment>
		</div>
	);
}

export default App;
