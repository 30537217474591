import { useState, useEffect, Fragment } from 'react';
import { useNavigate } from 'react-router-dom';
import './PageHeadline.css';
import UserProfileInfo from '../account/userProfileInfo/UserProfileInfo';
import { useStateValue } from '../../StateProvider';
import { GetLoginAsToken } from '../../Services';
import { RemoveSalesUserForLoginAs, setToken } from '../../utils/StorageUtil';
import { actionTypes } from '../../reducer';

const PageHeadline = ({
	campaign,
	headline,
	linkText = 'Go Back',
	linkUrl = '',
	className = '',
	buttonsHtml = null,
	leftSideHtml = null,
	showUserInfo = true
}) => {
	const [ { user, salesUserForLoginAs }, dispatch ] = useStateValue();
	const navigate = useNavigate();
	const [ showLoginAsWarning, setShowLoginAsWarning ] = useState(false);

	useEffect(
		() => {
			if (user && salesUserForLoginAs) {
				if (user.id !== salesUserForLoginAs.id) {
					setShowLoginAsWarning(true);
				} else {
					setShowLoginAsWarning(false);
				}
			} else {
				setShowLoginAsWarning(false);
			}
		},
		[ user, salesUserForLoginAs ]
	);

	const exitLoginAs = async () => {
		if (salesUserForLoginAs) {
			let data = { id: salesUserForLoginAs.id };
			//get sales user token
			const salesUserForLoginAsTokenResponse = await GetLoginAsToken(data);
			if (salesUserForLoginAsTokenResponse) {
				setToken(salesUserForLoginAsTokenResponse);
				dispatch({
					type : actionTypes.SET_USER,
					user : salesUserForLoginAs
				});

				RemoveSalesUserForLoginAs();

				dispatch({
					type                : actionTypes.SET_SALES_USER_FOR_LOGIN_AS,
					salesUserForLoginAs : null
				});

				window.location.href = '/campaigns';
				// navigate('/campaigns');
			}
		}
	};

	return (
		<Fragment>
			{showLoginAsWarning && (
				<div className={`page-header-container pt-0 ${className ? className : ''}`}>
					<div className="col-12">
						<div className="logged-in-wrapper">
							<span>Logged in as {user.email}</span>
							<button className="btn btn-link p-0 ms-3" onClick={exitLoginAs}>
								EXIT
							</button>
						</div>
					</div>
				</div>
			)}

			<div className={`page-header-container ${className ? className : ''}`}>
				<div className="col-12 col-md-6 col-xl-9 mb-2 mb-sm-0">
					{headline && (
						<div className="headline-wrapper">
							<div className="d-flex flex-column flex-xl-row align-items-start align-items-xl-center">
								{/* {linkUrl && (
								<button
									className="btn btn-link me-4 ps-0 headline-link"
									onClick={() => navigate(linkUrl)}
								>
									<i className="fa-solid fa-arrow-left-long me-2" />
									{linkText}
								</button>
							)} */}
								<h5 className="headline-title text-truncate">
									<span>{headline}</span>
								</h5>
							</div>
						</div>
					)}
					{leftSideHtml && <Fragment>{leftSideHtml}</Fragment>}
				</div>

				<div className="col-12 col-md-6 col-xl-3 justify-content-end">
					{buttonsHtml && <Fragment>{buttonsHtml}</Fragment>}
				</div>

				{/* {buttonsHtml && <Fragment>{buttonsHtml}</Fragment>} */}

				{/* {user && showUserInfo && <UserProfileInfo />} */}
			</div>
		</Fragment>
	);
};

export default PageHeadline;
